<script lang="ts" setup>
import type { HomeBanner } from "@/types";
const { t } = useT();

const { games } = useHomePage();

const { data: appInit } = useAppInitData();

const { data: otpGetData, isOneClickRegister } = useOtpGetFlow(true);

const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);
const { isPersonalOffer, registrationRewards, registrationRewardWithoutCode } = useCheckRewardsData();
const coins = computed(() => registrationRewards.value?.coins || registrationRewardWithoutCode.value?.coins);
const entries = computed(() => registrationRewards.value?.entries);

const isSocialEnabled = computed(
	() =>
		otpGetData.value?.registration_form?.googleAuthEnabled || otpGetData.value?.registration_form?.facebookAuthEnabled
);

const props = defineProps<{ banner: HomeBanner }>();

const findGame = (slug: string) => games.value?.find((el) => el.slug === slug.replace("game=", "") || {});
const router = useRouter();

const buttonClick = () => {
	if (appInit.value?.isGuest || props.banner.buttonLink === "/register/") {
		if (isOneClickRegister) {
			open("LazyOModalOneClickSignup");
			return;
		}
		open("LazyOModalSignup", { location: "banner" });
		return;
	}

	if (!props.banner.buttonLink) {
		return;
	}

	if (props.banner.buttonLink.includes("facebook")) {
		window.open(props.banner.buttonLink, "_blank");
		return;
	}

	if (props.banner.buttonLink.includes("game=")) {
		const slug = findGame(props.banner.buttonLink)?.slug;
		handleOpenGame(slug);
		return;
	}
	router.push(props.banner.buttonLink);
};

const route = useRoute();

const storePromoOffer = () => {
	const { promoOffer } = route.query;
	if (promoOffer && !Array.isArray(promoOffer)) {
		localStorage.setItem("promoOffer", promoOffer);
	}
};

const handleClickBtn = (socialName: string) => {
	storePromoOffer();

	dispatchGAEvent({
		event: "click_button",
		button_name: socialName.toLowerCase(),
		location: "registration_window"
	});
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
	>
		<template #default>
			<AText as="div" type="base h7-md" :modifiers="['uppercase', 'italic', 'medium']">
				<span class="slide-item__header" v-html="banner?.title" />
			</AText>
		</template>
		<template #description>
			<div class="banner-title">
				<div class="prize-container">
					<AText :size="28" class="text-natural-100" :modifiers="['bold', 'center']">
						<NuxtIcon name="28/coins" class="icon" filled />
						<i18n-t keypath="signup.modal.prize.coins">
							<template #key>
								{{ numberFormat(Number(coins)) }}
							</template>
						</i18n-t>
					</AText>
				</div>
				<div v-if="entries && isPersonalOffer" class="prize-container">
					<AText :size="28" class="text text-natural-100" :modifiers="['bold', 'center']">
						+
						<NuxtIcon name="28/entries" class="icon" filled />
						<i18n-t keypath="signup.modal.prize.entries">
							<template #key>
								{{ numberFormat(Number(entries)) }}
							</template>
						</i18n-t>
					</AText>
				</div>
			</div>
		</template>
		<template #actions>
			<div class="socials d-flex flex-column flex-md-row align-items-center gap-4">
				<AButton variant="primary" size="md" class="app-banner__btn" @click="buttonClick">
					{{ banner.buttonText }}
				</AButton>

				<div v-if="isSocialEnabled" class="socials__buttons d-flex align-items-center gap-4">
					<AText type="h6">{{ t("or") }}</AText>
					<NuxtLink v-if="otpGetData?.registration_form?.googleAuthEnabled" to="/login/Google/" external>
						<AButton
							variant="neutral"
							size="md"
							name="google"
							class="app-banner__btn__socials"
							@click="handleClickBtn('google')"
						>
							<NuxtIcon name="20/google" filled class="google-icon" />
						</AButton>
					</NuxtLink>
					<NuxtLink v-if="otpGetData?.registration_form?.facebookAuthEnabled" to="/login/facebook/" external>
						<AButton
							variant="neutral"
							size="md"
							name="facebook"
							class="app-banner__btn__socials"
							@click="handleClickBtn('facebook')"
						>
							<NuxtIcon name="20/fb" filled class="fb-icon" />
						</AButton>
					</NuxtLink>
				</div>
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
$fb-path-color: #3d6be3;
.fb-icon {
	&:deep(svg),
	&:deep(svg path) {
		fill: $fb-path-color;
	}
}
.fb-icon,
.google-icon {
	font-size: 24px;
	margin: 0 2px;
}

.banner-title {
	display: flex;
	flex-direction: column;
	width: 100%;

	.prize-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;

		.text {
			display: flex;
			align-items: center;
		}

		.icon {
			padding: 0 4px;
			display: inline-block;

			&:deep(svg) {
				height: 28px;
				width: 28px;
			}
		}
	}

	.prize {
		align-items: center;
		width: 220px;

		&.coins {
			align-items: flex-start;
		}

		span {
			text-align: center;
		}
	}
}

.slide-item {
	&__header {
		:deep(.entries) {
			color: var(--primary);
		}
	}

	&__description {
		:deep(.coins) {
			padding: 0 gutter(0.5) 0 gutter(4.5);
			position: relative;

			&:before {
				content: "";
				background: url("/nuxt-img/banners/coins@2x.png");
				background-size: contain;
				position: absolute;
				top: calc(50% - 13px);
				left: 7px;
				display: block;
				width: 26px;
				height: 26px;
			}
		}
	}
}
</style>
